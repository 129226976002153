export default {
    'province_list': {
        100000: '北京',
        110000: '天津',
        120000: '河北省',
        130000: '山西省',
        140000: '内蒙古自治区',
        150000: '辽宁省',
        160000: '吉林省',
        170000: '黑龙江省',
        180000: '上海',
        190000: '江苏省',
        200000: '浙江省',
        210000: '安徽省',
        220000: '福建省',
        230000: '江西省',
        240000: '山东省',
        250000: '河南省',
        260000: '湖北省',
        270000: '湖南省',
        280000: '广东省',
        290000: '广西壮族自治区',
        300000: '海南省',
        310000: '重庆',
        320000: '四川省',
        330000: '贵州省',
        340000: '云南省',
        350000: '西藏自治区',
        360000: '陕西省',
        370000: '甘肃省',
        380000: '青海省',
        390000: '宁夏回族自治区',
        400000: '新疆维吾尔自治区',
    },
    'city_list': {
        100100: '北京市',
        110100: '天津市',
        120100: '石家庄市',
        120200: '唐山市',
        120300: '秦皇岛市',
        120400: '邯郸市',
        120500: '邢台市',
        120600: '保定市',
        120700: '张家口市',
        120800: '承德市',
        120900: '沧州市',
        121000: '廊坊市',
        121100: '衡水市',
        130100: '太原市',
        130200: '大同市',
        130300: '阳泉市',
        130400: '长治市',
        130500: '晋城市',
        130600: '朔州市',
        130700: '晋中市',
        130800: '运城市',
        130900: '忻州市',
        131000: '临汾市',
        131100: '吕梁市',
        140100: '呼和浩特市',
        140200: '包头市',
        140300: '乌海市',
        140400: '赤峰市',
        140500: '通辽市',
        140600: '鄂尔多斯市',
        140700: '呼伦贝尔市',
        140800: '巴彦淖尔市',
        140900: '乌兰察布市',
        141000: '兴安盟',
        141100: '锡林郭勒盟',
        141200: '阿拉善盟',
        150100: '沈阳市',
        150200: '大连市',
        150300: '鞍山市',
        150400: '抚顺市',
        150500: '本溪市',
        150600: '丹东市',
        150700: '锦州市',
        150800: '营口市',
        150900: '阜新市',
        151000: '辽阳市',
        151100: '盘锦市',
        151200: '铁岭市',
        151300: '朝阳市',
        151400: '葫芦岛市',
        160100: '长春市',
        160200: '吉林市',
        160300: '四平市',
        160400: '辽源市',
        160500: '通化市',
        160600: '白山市',
        160700: '松原市',
        160800: '白城市',
        160900: '延边朝鲜族自治州',
        170100: '哈尔滨市',
        170200: '齐齐哈尔市',
        170300: '鸡西市',
        170400: '鹤岗市',
        170500: '双鸭山市',
        170600: '大庆市',
        170700: '伊春市',
        170800: '佳木斯市',
        170900: '七台河市',
        171000: '牡丹江市',
        171100: '黑河市',
        171200: '绥化市',
        171300: '大兴安岭地区',
        180100: '上海市',
        190100: '南京市',
        190200: '无锡市',
        190300: '徐州市',
        190400: '常州市',
        190500: '苏州市',
        190600: '南通市',
        190700: '连云港市',
        190800: '淮安市',
        190900: '盐城市',
        191000: '扬州市',
        191100: '镇江市',
        191200: '泰州市',
        191300: '宿迁市',
        200100: '杭州市',
        200200: '宁波市',
        200300: '温州市',
        200400: '嘉兴市',
        200500: '湖州市',
        200600: '绍兴市',
        200700: '金华市',
        200800: '衢州市',
        200900: '舟山市',
        201000: '台州市',
        201100: '丽水市',
        210100: '合肥市',
        210200: '芜湖市',
        210300: '蚌埠市',
        210400: '淮南市',
        210500: '马鞍山市',
        210600: '淮北市',
        210700: '铜陵市',
        210800: '安庆市',
        210900: '黄山市',
        211000: '滁州市',
        211100: '阜阳市',
        211200: '宿州市',
        211300: '六安市',
        211400: '亳州市',
        211500: '池州市',
        211600: '宣城市',
        220100: '福州市',
        220200: '厦门市',
        220300: '莆田市',
        220400: '三明市',
        220500: '泉州市',
        220600: '漳州市',
        220700: '南平市',
        220800: '龙岩市',
        220900: '宁德市',
        230100: '南昌市',
        230200: '景德镇市',
        230300: '萍乡市',
        230400: '九江市',
        230500: '新余市',
        230600: '鹰潭市',
        230700: '赣州市',
        230800: '吉安市',
        230900: '宜春市',
        231000: '抚州市',
        231100: '上饶市',
        240100: '济南市',
        240200: '青岛市',
        240300: '淄博市',
        240400: '枣庄市',
        240500: '东营市',
        240600: '烟台市',
        240700: '潍坊市',
        240800: '济宁市',
        240900: '泰安市',
        241000: '威海市',
        241100: '日照市',
        241200: '莱芜市',
        241300: '临沂市',
        241400: '德州市',
        241500: '聊城市',
        241600: '滨州市',
        241700: '菏泽市',
        250100: '郑州市',
        250200: '开封市',
        250300: '洛阳市',
        250400: '平顶山市',
        250500: '安阳市',
        250600: '鹤壁市',
        250700: '新乡市',
        250800: '焦作市',
        250900: '濮阳市',
        251000: '许昌市',
        251100: '漯河市',
        251200: '三门峡市',
        251300: '南阳市',
        251400: '商丘市',
        251500: '信阳市',
        251600: '周口市',
        251700: '驻马店市',
        251800: '济源市',
        260100: '武汉市',
        260200: '黄石市',
        260300: '十堰市',
        260400: '宜昌市',
        260500: '襄阳市',
        260600: '鄂州市',
        260700: '荆门市',
        260800: '孝感市',
        260900: '荆州市',
        261000: '黄冈市',
        261100: '咸宁市',
        261200: '随州市',
        261300: '恩施土家族苗族自治州',
        261400: '仙桃市',
        261500: '潜江市',
        261600: '天门市',
        261700: '神农架林区',
        270100: '长沙市',
        270200: '株洲市',
        270300: '湘潭市',
        270400: '衡阳市',
        270500: '邵阳市',
        270600: '岳阳市',
        270700: '常德市',
        270800: '张家界市',
        270900: '益阳市',
        271000: '郴州市',
        271100: '永州市',
        271200: '怀化市',
        271300: '娄底市',
        271400: '湘西土家族苗族自治州',
        280100: '广州市',
        280200: '韶关市',
        280300: '深圳市',
        280400: '珠海市',
        280500: '汕头市',
        280600: '佛山市',
        280700: '江门市',
        280800: '湛江市',
        280900: '茂名市',
        281000: '肇庆市',
        281100: '惠州市',
        281200: '梅州市',
        281300: '汕尾市',
        281400: '河源市',
        281500: '阳江市',
        281600: '清远市',
        281700: '东莞市',
        281800: '中山市',
        281900: '东沙群岛',
        282000: '潮州市',
        282100: '揭阳市',
        282200: '云浮市',
        290100: '南宁市',
        290200: '柳州市',
        290300: '桂林市',
        290400: '梧州市',
        290500: '北海市',
        290600: '防城港市',
        290700: '钦州市',
        290800: '贵港市',
        290900: '玉林市',
        291000: '百色市',
        291100: '贺州市',
        291200: '河池市',
        291300: '来宾市',
        291400: '崇左市',
        300100: '海口市',
        300200: '三亚市',
        300300: '三沙市',
        300400: '儋州市',
        300500: '五指山市',
        300600: '琼海市',
        300700: '文昌市',
        300800: '万宁市',
        300900: '东方市',
        301000: '定安县',
        301100: '屯昌县',
        301200: '澄迈县',
        301300: '临高县',
        301400: '白沙黎族自治县',
        301500: '昌江黎族自治县',
        301600: '乐东黎族自治县',
        301700: '陵水黎族自治县',
        301800: '保亭黎族苗族自治县',
        301900: '琼中黎族苗族自治县',
        310100: '重庆市',
        320100: '成都市',
        320200: '自贡市',
        320300: '攀枝花市',
        320400: '泸州市',
        320500: '德阳市',
        320600: '绵阳市',
        320700: '广元市',
        320800: '遂宁市',
        320900: '内江市',
        321000: '乐山市',
        321100: '南充市',
        321200: '眉山市',
        321300: '宜宾市',
        321400: '广安市',
        321500: '达州市',
        321600: '雅安市',
        321700: '巴中市',
        321800: '资阳市',
        321900: '阿坝藏族羌族自治州',
        322000: '甘孜藏族自治州',
        322100: '凉山彝族自治州',
        330100: '贵阳市',
        330200: '六盘水市',
        330300: '遵义市',
        330400: '安顺市',
        330500: '铜仁市',
        330600: '黔西南布依族苗族自治州',
        330700: '毕节市',
        330800: '黔东南苗族侗族自治州',
        330900: '黔南布依族苗族自治州',
        340100: '昆明市',
        340200: '曲靖市',
        340300: '玉溪市',
        340400: '保山市',
        340500: '昭通市',
        340600: '丽江市',
        340700: '普洱市',
        340800: '临沧市',
        340900: '楚雄彝族自治州',
        341000: '红河哈尼族彝族自治州',
        341100: '文山壮族苗族自治州',
        341200: '西双版纳傣族自治州',
        341300: '大理白族自治州',
        341400: '德宏傣族景颇族自治州',
        341500: '怒江傈僳族自治州',
        341600: '迪庆藏族自治州',
        350100: '拉萨市',
        350200: '昌都市',
        350300: '山南地区',
        350400: '日喀则市',
        350500: '那曲地区',
        350600: '阿里地区',
        350700: '林芝市',
        360100: '西安市',
        360200: '铜川市',
        360300: '宝鸡市',
        360400: '咸阳市',
        360500: '渭南市',
        360600: '延安市',
        360700: '汉中市',
        360800: '榆林市',
        360900: '安康市',
        361000: '商洛市',
        370100: '兰州市',
        370200: '嘉峪关市',
        370300: '金昌市',
        370400: '白银市',
        370500: '天水市',
        370600: '武威市',
        370700: '张掖市',
        370800: '平凉市',
        370900: '酒泉市',
        371000: '庆阳市',
        371100: '定西市',
        371200: '陇南市',
        371300: '临夏回族自治州',
        371400: '甘南藏族自治州',
        380100: '西宁市',
        380200: '海东市',
        380300: '海北藏族自治州',
        380400: '黄南藏族自治州',
        380500: '海南藏族自治州',
        380600: '果洛藏族自治州',
        380700: '玉树藏族自治州',
        380800: '海西蒙古族藏族自治州',
        390100: '银川市',
        390200: '石嘴山市',
        390300: '吴忠市',
        390400: '固原市',
        390500: '中卫市',
        400100: '乌鲁木齐市',
        400200: '克拉玛依市',
        400300: '吐鲁番市',
        400400: '哈密地区',
        400500: '昌吉回族自治州',
        400600: '博尔塔拉蒙古自治州',
        400700: '巴音郭楞蒙古自治州',
        400800: '阿克苏地区',
        400900: '克孜勒苏柯尔克孜自治州',
        401000: '喀什地区',
        401100: '和田地区',
        401200: '伊犁哈萨克自治州',
        401300: '塔城地区',
        401400: '阿勒泰地区',
        401500: '石河子市',
        401600: '阿拉尔市',
        401700: '图木舒克市',
        401800: '五家渠市',
        401900: '北屯市',
        402000: '铁门关市',
        402100: '双河市',
        402200: '可克达拉市',
        402300: '昆玉市',
    },
    'county_list': {
        100101: '东城区',
        100102: '西城区',
        100103: '朝阳区',
        100104: '丰台区',
        100105: '石景山区',
        100106: '海淀区',
        100107: '门头沟区',
        100108: '房山区',
        100109: '通州区',
        100110: '顺义区',
        100111: '昌平区',
        100112: '大兴区',
        100113: '怀柔区',
        100114: '平谷区',
        100115: '密云县',
        100116: '延庆县',
        100117: '其它区',
        110101: '和平区',
        110102: '河东区',
        110103: '河西区',
        110104: '南开区',
        110105: '河北区',
        110106: '红桥区',
        110107: '塘沽区',
        110108: '汉沽区',
        110109: '大港区',
        110110: '东丽区',
        110111: '西青区',
        110112: '津南区',
        110113: '北辰区',
        110114: '武清区',
        110115: '宝坻区',
        110116: '滨海新区',
        110117: '宁河县',
        110118: '静海县',
        110119: '蓟县',
        110120: '其它区',
        120101: '长安区',
        120102: '桥东区',
        120103: '桥西区',
        120104: '新华区',
        120105: '井陉矿区',
        120106: '裕华区',
        120107: '井陉县',
        120108: '正定县',
        120109: '栾城区',
        120110: '行唐县',
        120111: '灵寿县',
        120112: '高邑县',
        120113: '深泽县',
        120114: '赞皇县',
        120115: '无极县',
        120116: '平山县',
        120117: '元氏县',
        120118: '赵县',
        120119: '辛集市',
        120120: '藁城区',
        120121: '晋州市',
        120122: '新乐市',
        120123: '鹿泉区',
        120124: '其它区',
        120201: '路南区',
        120202: '路北区',
        120203: '古冶区',
        120204: '开平区',
        120205: '丰南区',
        120206: '丰润区',
        120207: '滦县',
        120208: '滦南县',
        120209: '乐亭县',
        120210: '迁西县',
        120211: '玉田县',
        120212: '曹妃甸区',
        120213: '遵化市',
        120214: '迁安市',
        120215: '其它区',
        120301: '海港区',
        120302: '山海关区',
        120303: '北戴河区',
        120304: '青龙满族自治县',
        120305: '昌黎县',
        120306: '抚宁县',
        120307: '卢龙县',
        120308: '其它区',
        120309: '经济技术开发区',
        120401: '邯山区',
        120402: '丛台区',
        120403: '复兴区',
        120404: '峰峰矿区',
        120405: '邯郸县',
        120406: '临漳县',
        120407: '成安县',
        120408: '大名县',
        120409: '涉县',
        120410: '磁县',
        120411: '肥乡县',
        120412: '永年县',
        120413: '邱县',
        120414: '鸡泽县',
        120415: '广平县',
        120416: '馆陶县',
        120417: '魏县',
        120418: '曲周县',
        120419: '武安市',
        120420: '其它区',
        120501: '桥东区',
        120502: '桥西区',
        120503: '邢台县',
        120504: '临城县',
        120505: '内丘县',
        120506: '柏乡县',
        120507: '隆尧县',
        120508: '任县',
        120509: '南和县',
        120510: '宁晋县',
        120511: '巨鹿县',
        120512: '新河县',
        120513: '广宗县',
        120514: '平乡县',
        120515: '威县',
        120516: '清河县',
        120517: '临西县',
        120518: '南宫市',
        120519: '沙河市',
        120520: '其它区',
        120601: '新市区',
        120602: '北市区',
        120603: '南市区',
        120604: '满城县',
        120605: '清苑县',
        120606: '涞水县',
        120607: '阜平县',
        120608: '徐水县',
        120609: '定兴县',
        120610: '唐县',
        120611: '高阳县',
        120612: '容城县',
        120613: '涞源县',
        120614: '望都县',
        120615: '安新县',
        120616: '易县',
        120617: '曲阳县',
        120618: '蠡县',
        120619: '顺平县',
        120620: '博野县',
        120621: '雄县',
        120622: '涿州市',
        120623: '定州市',
        120624: '安国市',
        120625: '高碑店市',
        120626: '高开区',
        120627: '其它区',
        120701: '桥东区',
        120702: '桥西区',
        120703: '宣化区',
        120704: '下花园区',
        120705: '宣化县',
        120706: '张北县',
        120707: '康保县',
        120708: '沽源县',
        120709: '尚义县',
        120710: '蔚县',
        120711: '阳原县',
        120712: '怀安县',
        120713: '万全县',
        120714: '怀来县',
        120715: '涿鹿县',
        120716: '赤城县',
        120717: '崇礼县',
        120718: '其它区',
        120801: '双桥区',
        120802: '双滦区',
        120803: '鹰手营子矿区',
        120804: '承德县',
        120805: '兴隆县',
        120806: '平泉县',
        120807: '滦平县',
        120808: '隆化县',
        120809: '丰宁满族自治县',
        120810: '宽城满族自治县',
        120811: '围场满族蒙古族自治县',
        120812: '其它区',
        120901: '新华区',
        120902: '运河区',
        120903: '沧县',
        120904: '青县',
        120905: '东光县',
        120906: '海兴县',
        120907: '盐山县',
        120908: '肃宁县',
        120909: '南皮县',
        120910: '吴桥县',
        120911: '献县',
        120912: '孟村回族自治县',
        120913: '泊头市',
        120914: '任丘市',
        120915: '黄骅市',
        120916: '河间市',
        120917: '其它区',
        121001: '安次区',
        121002: '广阳区',
        121003: '固安县',
        121004: '永清县',
        121005: '香河县',
        121006: '大城县',
        121007: '文安县',
        121008: '大厂回族自治县',
        121009: '开发区',
        121010: '燕郊经济技术开发区',
        121011: '霸州市',
        121012: '三河市',
        121013: '其它区',
        121101: '桃城区',
        121102: '枣强县',
        121103: '武邑县',
        121104: '武强县',
        121105: '饶阳县',
        121106: '安平县',
        121107: '故城县',
        121108: '景县',
        121109: '阜城县',
        121110: '冀州市',
        121111: '深州市',
        121112: '其它区',
        130101: '小店区',
        130102: '迎泽区',
        130103: '杏花岭区',
        130104: '尖草坪区',
        130105: '万柏林区',
        130106: '晋源区',
        130107: '清徐县',
        130108: '阳曲县',
        130109: '娄烦县',
        130110: '古交市',
        130111: '其它区',
        130201: '城区',
        130202: '矿区',
        130203: '南郊区',
        130204: '新荣区',
        130205: '阳高县',
        130206: '天镇县',
        130207: '广灵县',
        130208: '灵丘县',
        130209: '浑源县',
        130210: '左云县',
        130211: '大同县',
        130212: '其它区',
        130301: '城区',
        130302: '矿区',
        130303: '郊区',
        130304: '平定县',
        130305: '盂县',
        130306: '其它区',
        130401: '长治县',
        130402: '襄垣县',
        130403: '屯留县',
        130404: '平顺县',
        130405: '黎城县',
        130406: '壶关县',
        130407: '长子县',
        130408: '武乡县',
        130409: '沁县',
        130410: '沁源县',
        130411: '潞城市',
        130412: '城区',
        130413: '郊区',
        130414: '高新区',
        130415: '其它区',
        130501: '城区',
        130502: '沁水县',
        130503: '阳城县',
        130504: '陵川县',
        130505: '泽州县',
        130506: '高平市',
        130507: '其它区',
        130601: '朔城区',
        130602: '平鲁区',
        130603: '山阴县',
        130604: '应县',
        130605: '右玉县',
        130606: '怀仁县',
        130607: '其它区',
        130701: '榆次区',
        130702: '榆社县',
        130703: '左权县',
        130704: '和顺县',
        130705: '昔阳县',
        130706: '寿阳县',
        130707: '太谷县',
        130708: '祁县',
        130709: '平遥县',
        130710: '灵石县',
        130711: '介休市',
        130712: '其它区',
        130801: '盐湖区',
        130802: '临猗县',
        130803: '万荣县',
        130804: '闻喜县',
        130805: '稷山县',
        130806: '新绛县',
        130807: '绛县',
        130808: '垣曲县',
        130809: '夏县',
        130810: '平陆县',
        130811: '芮城县',
        130812: '永济市',
        130813: '河津市',
        130814: '其它区',
        130901: '忻府区',
        130902: '定襄县',
        130903: '五台县',
        130904: '代县',
        130905: '繁峙县',
        130906: '宁武县',
        130907: '静乐县',
        130908: '神池县',
        130909: '五寨县',
        130910: '岢岚县',
        130911: '河曲县',
        130912: '保德县',
        130913: '偏关县',
        130914: '原平市',
        130915: '其它区',
        131001: '尧都区',
        131002: '曲沃县',
        131003: '翼城县',
        131004: '襄汾县',
        131005: '洪洞县',
        131006: '古县',
        131007: '安泽县',
        131008: '浮山县',
        131009: '吉县',
        131010: '乡宁县',
        131011: '大宁县',
        131012: '隰县',
        131013: '永和县',
        131014: '蒲县',
        131015: '汾西县',
        131016: '侯马市',
        131017: '霍州市',
        131018: '其它区',
        131101: '离石区',
        131102: '文水县',
        131103: '交城县',
        131104: '兴县',
        131105: '临县',
        131106: '柳林县',
        131107: '石楼县',
        131108: '岚县',
        131109: '方山县',
        131110: '中阳县',
        131111: '交口县',
        131112: '孝义市',
        131113: '汾阳市',
        131114: '其它区',
        140101: '新城区',
        140102: '回民区',
        140103: '玉泉区',
        140104: '赛罕区',
        140105: '土默特左旗',
        140106: '托克托县',
        140107: '和林格尔县',
        140108: '清水河县',
        140109: '武川县',
        140110: '其它区',
        140201: '东河区',
        140202: '昆都仑区',
        140203: '青山区',
        140204: '石拐区',
        140205: '白云鄂博矿区',
        140206: '九原区',
        140207: '土默特右旗',
        140208: '固阳县',
        140209: '达尔罕茂明安联合旗',
        140210: '其它区',
        140301: '海勃湾区',
        140302: '海南区',
        140303: '乌达区',
        140304: '其它区',
        140401: '红山区',
        140402: '元宝山区',
        140403: '松山区',
        140404: '阿鲁科尔沁旗',
        140405: '巴林左旗',
        140406: '巴林右旗',
        140407: '林西县',
        140408: '克什克腾旗',
        140409: '翁牛特旗',
        140410: '喀喇沁旗',
        140411: '宁城县',
        140412: '敖汉旗',
        140413: '其它区',
        140501: '科尔沁区',
        140502: '科尔沁左翼中旗',
        140503: '科尔沁左翼后旗',
        140504: '开鲁县',
        140505: '库伦旗',
        140506: '奈曼旗',
        140507: '扎鲁特旗',
        140508: '霍林郭勒市',
        140509: '其它区',
        140601: '东胜区',
        140602: '达拉特旗',
        140603: '准格尔旗',
        140604: '鄂托克前旗',
        140605: '鄂托克旗',
        140606: '杭锦旗',
        140607: '乌审旗',
        140608: '伊金霍洛旗',
        140609: '其它区',
        140701: '海拉尔区',
        140702: '扎赉诺尔区',
        140703: '阿荣旗',
        140704: '莫力达瓦达斡尔族自治旗',
        140705: '鄂伦春自治旗',
        140706: '鄂温克族自治旗',
        140707: '陈巴尔虎旗',
        140708: '新巴尔虎左旗',
        140709: '新巴尔虎右旗',
        140710: '满洲里市',
        140711: '牙克石市',
        140712: '扎兰屯市',
        140713: '额尔古纳市',
        140714: '根河市',
        140715: '其它区',
        140801: '临河区',
        140802: '五原县',
        140803: '磴口县',
        140804: '乌拉特前旗',
        140805: '乌拉特中旗',
        140806: '乌拉特后旗',
        140807: '杭锦后旗',
        140808: '其它区',
        140901: '集宁区',
        140902: '卓资县',
        140903: '化德县',
        140904: '商都县',
        140905: '兴和县',
        140906: '凉城县',
        140907: '察哈尔右翼前旗',
        140908: '察哈尔右翼中旗',
        140909: '察哈尔右翼后旗',
        140910: '四子王旗',
        140911: '丰镇市',
        140912: '其它区',
        141001: '乌兰浩特市',
        141002: '阿尔山市',
        141003: '科尔沁右翼前旗',
        141004: '科尔沁右翼中旗',
        141005: '扎赉特旗',
        141006: '突泉县',
        141007: '其它区',
        141101: '二连浩特市',
        141102: '锡林浩特市',
        141103: '阿巴嘎旗',
        141104: '苏尼特左旗',
        141105: '苏尼特右旗',
        141106: '东乌珠穆沁旗',
        141107: '西乌珠穆沁旗',
        141108: '太仆寺旗',
        141109: '镶黄旗',
        141110: '正镶白旗',
        141111: '正蓝旗',
        141112: '多伦县',
        141113: '其它区',
        141201: '阿拉善左旗',
        141202: '阿拉善右旗',
        141203: '额济纳旗',
        141204: '其它区',
        150101: '和平区',
        150102: '沈河区',
        150103: '大东区',
        150104: '皇姑区',
        150105: '铁西区',
        150106: '苏家屯区',
        150107: '浑南区',
        150108: '新城子区',
        150109: '于洪区',
        150110: '辽中县',
        150111: '康平县',
        150112: '法库县',
        150113: '新民市',
        150114: '浑南新区',
        150115: '张士开发区',
        150116: '沈北新区',
        150117: '其它区',
        150201: '中山区',
        150202: '西岗区',
        150203: '沙河口区',
        150204: '甘井子区',
        150205: '旅顺口区',
        150206: '金州区',
        150207: '长海县',
        150208: '开发区',
        150209: '瓦房店市',
        150210: '普兰店市',
        150211: '庄河市',
        150212: '岭前区',
        150213: '其它区',
        150301: '铁东区',
        150302: '铁西区',
        150303: '立山区',
        150304: '千山区',
        150305: '台安县',
        150306: '岫岩满族自治县',
        150307: '高新区',
        150308: '海城市',
        150309: '其它区',
        150401: '新抚区',
        150402: '东洲区',
        150403: '望花区',
        150404: '顺城区',
        150405: '抚顺县',
        150406: '新宾满族自治县',
        150407: '清原满族自治县',
        150408: '其它区',
        150501: '平山区',
        150502: '溪湖区',
        150503: '明山区',
        150504: '南芬区',
        150505: '本溪满族自治县',
        150506: '桓仁满族自治县',
        150507: '其它区',
        150601: '元宝区',
        150602: '振兴区',
        150603: '振安区',
        150604: '宽甸满族自治县',
        150605: '东港市',
        150606: '凤城市',
        150607: '其它区',
        150701: '古塔区',
        150702: '凌河区',
        150703: '太和区',
        150704: '黑山县',
        150705: '义县',
        150706: '凌海市',
        150707: '北镇市',
        150708: '其它区',
        150801: '站前区',
        150802: '西市区',
        150803: '鲅鱼圈区',
        150804: '老边区',
        150805: '盖州市',
        150806: '大石桥市',
        150807: '其它区',
        150901: '海州区',
        150902: '新邱区',
        150903: '太平区',
        150904: '清河门区',
        150905: '细河区',
        150906: '阜新蒙古族自治县',
        150907: '彰武县',
        150908: '其它区',
        151001: '白塔区',
        151002: '文圣区',
        151003: '宏伟区',
        151004: '弓长岭区',
        151005: '太子河区',
        151006: '辽阳县',
        151007: '灯塔市',
        151008: '其它区',
        151101: '双台子区',
        151102: '兴隆台区',
        151103: '大洼县',
        151104: '盘山县',
        151105: '其它区',
        151201: '银州区',
        151202: '清河区',
        151203: '铁岭县',
        151204: '西丰县',
        151205: '昌图县',
        151206: '调兵山市',
        151207: '开原市',
        151208: '其它区',
        151301: '双塔区',
        151302: '龙城区',
        151303: '朝阳县',
        151304: '建平县',
        151305: '喀左县',
        151306: '北票市',
        151307: '凌源市',
        151308: '其它区',
        151401: '连山区',
        151402: '龙港区',
        151403: '南票区',
        151404: '绥中县',
        151405: '建昌县',
        151406: '兴城市',
        151407: '其它区',
        160101: '南关区',
        160102: '宽城区',
        160103: '朝阳区',
        160104: '二道区',
        160105: '绿园区',
        160106: '双阳区',
        160107: '农安县',
        160108: '九台区',
        160109: '榆树市',
        160110: '德惠市',
        160111: '高新技术产业开发区',
        160112: '汽车产业开发区',
        160113: '经济技术开发区',
        160114: '净月旅游开发区',
        160115: '其它区',
        160201: '昌邑区',
        160202: '龙潭区',
        160203: '船营区',
        160204: '丰满区',
        160205: '永吉县',
        160206: '蛟河市',
        160207: '桦甸市',
        160208: '舒兰市',
        160209: '磐石市',
        160210: '其它区',
        160301: '铁西区',
        160302: '铁东区',
        160303: '梨树县',
        160304: '伊通满族自治县',
        160305: '公主岭市',
        160306: '双辽市',
        160307: '其它区',
        160401: '龙山区',
        160402: '西安区',
        160403: '东丰县',
        160404: '东辽县',
        160405: '其它区',
        160501: '东昌区',
        160502: '二道江区',
        160503: '通化县',
        160504: '辉南县',
        160505: '柳河县',
        160506: '梅河口市',
        160507: '集安市',
        160508: '其它区',
        160601: '浑江区',
        160602: '抚松县',
        160603: '靖宇县',
        160604: '长白朝鲜族自治县',
        160605: '江源区',
        160606: '临江市',
        160607: '其它区',
        160701: '宁江区',
        160702: '前郭尔罗斯蒙古族自治县',
        160703: '长岭县',
        160704: '乾安县',
        160705: '扶余市',
        160706: '其它区',
        160801: '洮北区',
        160802: '镇赉县',
        160803: '通榆县',
        160804: '洮南市',
        160805: '大安市',
        160806: '其它区',
        160901: '延吉市',
        160902: '图们市',
        160903: '敦化市',
        160904: '珲春市',
        160905: '龙井市',
        160906: '和龙市',
        160907: '汪清县',
        160908: '安图县',
        160909: '其它区',
        170101: '道里区',
        170102: '南岗区',
        170103: '道外区',
        170104: '香坊区',
        170105: '动力区',
        170106: '平房区',
        170107: '松北区',
        170108: '呼兰区',
        170109: '依兰县',
        170110: '方正县',
        170111: '宾县',
        170112: '巴彦县',
        170113: '木兰县',
        170114: '通河县',
        170115: '延寿县',
        170116: '阿城区',
        170117: '双城区',
        170118: '尚志市',
        170119: '五常市',
        170120: '阿城市',
        170121: '其它区',
        170201: '龙沙区',
        170202: '建华区',
        170203: '铁锋区',
        170204: '昂昂溪区',
        170205: '富拉尔基区',
        170206: '碾子山区',
        170207: '梅里斯达斡尔族区',
        170208: '龙江县',
        170209: '依安县',
        170210: '泰来县',
        170211: '甘南县',
        170212: '富裕县',
        170213: '克山县',
        170214: '克东县',
        170215: '拜泉县',
        170216: '讷河市',
        170217: '其它区',
        170301: '鸡冠区',
        170302: '恒山区',
        170303: '滴道区',
        170304: '梨树区',
        170305: '城子河区',
        170306: '麻山区',
        170307: '鸡东县',
        170308: '虎林市',
        170309: '密山市',
        170310: '其它区',
        170401: '向阳区',
        170402: '工农区',
        170403: '南山区',
        170404: '兴安区',
        170405: '东山区',
        170406: '兴山区',
        170407: '萝北县',
        170408: '绥滨县',
        170409: '其它区',
        170501: '尖山区',
        170502: '岭东区',
        170503: '四方台区',
        170504: '宝山区',
        170505: '集贤县',
        170506: '友谊县',
        170507: '宝清县',
        170508: '饶河县',
        170509: '其它区',
        170601: '萨尔图区',
        170602: '龙凤区',
        170603: '让胡路区',
        170604: '红岗区',
        170605: '大同区',
        170606: '肇州县',
        170607: '肇源县',
        170608: '林甸县',
        170609: '杜尔伯特蒙古族自治县',
        170610: '其它区',
        170701: '伊春区',
        170702: '南岔区',
        170703: '友好区',
        170704: '西林区',
        170705: '翠峦区',
        170706: '新青区',
        170707: '美溪区',
        170708: '金山屯区',
        170709: '五营区',
        170710: '乌马河区',
        170711: '汤旺河区',
        170712: '带岭区',
        170713: '乌伊岭区',
        170714: '红星区',
        170715: '上甘岭区',
        170716: '嘉荫县',
        170717: '铁力市',
        170718: '其它区',
        170801: '永红区',
        170802: '向阳区',
        170803: '前进区',
        170804: '东风区',
        170805: '郊区',
        170806: '桦南县',
        170807: '桦川县',
        170808: '汤原县',
        170809: '抚远县',
        170810: '同江市',
        170811: '富锦市',
        170812: '其它区',
        170901: '新兴区',
        170902: '桃山区',
        170903: '茄子河区',
        170904: '勃利县',
        170905: '其它区',
        171001: '东安区',
        171002: '阳明区',
        171003: '爱民区',
        171004: '西安区',
        171005: '东宁县',
        171006: '林口县',
        171007: '绥芬河市',
        171008: '海林市',
        171009: '宁安市',
        171010: '穆棱市',
        171011: '其它区',
        171101: '爱辉区',
        171102: '嫩江县',
        171103: '逊克县',
        171104: '孙吴县',
        171105: '北安市',
        171106: '五大连池市',
        171107: '其它区',
        171201: '北林区',
        171202: '望奎县',
        171203: '兰西县',
        171204: '青冈县',
        171205: '庆安县',
        171206: '明水县',
        171207: '绥棱县',
        171208: '安达市',
        171209: '肇东市',
        171210: '海伦市',
        171211: '其它区',
        171301: '松岭区',
        171302: '新林区',
        171303: '呼中区',
        171304: '呼玛县',
        171305: '塔河县',
        171306: '漠河县',
        171307: '加格达奇区',
        171308: '其它区',
        180101: '黄浦区',
        180102: '卢湾区',
        180103: '徐汇区',
        180104: '长宁区',
        180105: '静安区',
        180106: '普陀区',
        180107: '闸北区',
        180108: '虹口区',
        180109: '杨浦区',
        180110: '闵行区',
        180111: '宝山区',
        180112: '嘉定区',
        180113: '浦东新区',
        180114: '金山区',
        180115: '松江区',
        180116: '青浦区',
        180117: '南汇区',
        180118: '奉贤区',
        180119: '川沙区',
        180120: '崇明县',
        180121: '其它区',
        190101: '玄武区',
        190102: '白下区',
        190103: '秦淮区',
        190104: '建邺区',
        190105: '鼓楼区',
        190106: '下关区',
        190107: '浦口区',
        190108: '栖霞区',
        190109: '雨花台区',
        190110: '江宁区',
        190111: '六合区',
        190112: '溧水区',
        190113: '高淳区',
        190114: '其它区',
        190201: '崇安区',
        190202: '南长区',
        190203: '北塘区',
        190204: '锡山区',
        190205: '惠山区',
        190206: '滨湖区',
        190207: '梁溪区',
        190208: '江阴市',
        190209: '宜兴市',
        190210: '新区',
        190211: '其它区',
        190301: '鼓楼区',
        190302: '云龙区',
        190303: '九里区',
        190304: '贾汪区',
        190305: '泉山区',
        190306: '丰县',
        190307: '沛县',
        190308: '铜山区',
        190309: '睢宁县',
        190310: '新沂市',
        190311: '邳州市',
        190312: '其它区',
        190401: '天宁区',
        190402: '钟楼区',
        190403: '戚墅堰区',
        190404: '新北区',
        190405: '武进区',
        190406: '溧阳市',
        190407: '金坛市',
        190408: '其它区',
        190501: '沧浪区',
        190502: '平江区',
        190503: '金阊区',
        190504: '虎丘区',
        190505: '吴中区',
        190506: '相城区',
        190507: '姑苏区',
        190508: '常熟市',
        190509: '张家港市',
        190510: '昆山市',
        190511: '吴江区',
        190512: '太仓市',
        190513: '新区',
        190514: '园区',
        190515: '其它区',
        190601: '崇川区',
        190602: '港闸区',
        190603: '通州区',
        190604: '海安县',
        190605: '如东县',
        190606: '启东市',
        190607: '如皋市',
        190608: '通州市',
        190609: '海门市',
        190610: '开发区',
        190611: '其它区',
        190701: '连云区',
        190702: '新浦区',
        190703: '海州区',
        190704: '赣榆区',
        190705: '东海县',
        190706: '灌云县',
        190707: '灌南县',
        190708: '其它区',
        190801: '清河区',
        190802: '淮安区',
        190803: '淮阴区',
        190804: '清浦区',
        190805: '涟水县',
        190806: '洪泽县',
        190807: '盱眙县',
        190808: '金湖县',
        190809: '其它区',
        190901: '亭湖区',
        190902: '盐都区',
        190903: '响水县',
        190904: '滨海县',
        190905: '阜宁县',
        190906: '射阳县',
        190907: '建湖县',
        190908: '东台市',
        190909: '大丰市',
        190910: '其它区',
        191001: '广陵区',
        191002: '邗江区',
        191003: '维扬区',
        191004: '宝应县',
        191005: '仪征市',
        191006: '高邮市',
        191007: '江都区',
        191008: '经济开发区',
        191009: '其它区',
        191101: '京口区',
        191102: '润州区',
        191103: '丹徒区',
        191104: '丹阳市',
        191105: '扬中市',
        191106: '句容市',
        191107: '其它区',
        191201: '海陵区',
        191202: '高港区',
        191203: '兴化市',
        191204: '靖江市',
        191205: '泰兴市',
        191206: '姜堰区',
        191207: '其它区',
        191301: '宿城区',
        191302: '宿豫区',
        191303: '沭阳县',
        191304: '泗阳县',
        191305: '泗洪县',
        191306: '其它区',
        200101: '上城区',
        200102: '下城区',
        200103: '江干区',
        200104: '拱墅区',
        200105: '西湖区',
        200106: '滨江区',
        200107: '萧山区',
        200108: '余杭区',
        200109: '桐庐县',
        200110: '淳安县',
        200111: '建德市',
        200112: '富阳区',
        200113: '临安市',
        200114: '其它区',
        200201: '海曙区',
        200202: '江东区',
        200203: '江北区',
        200204: '北仑区',
        200205: '镇海区',
        200206: '鄞州区',
        200207: '象山县',
        200208: '宁海县',
        200209: '余姚市',
        200210: '慈溪市',
        200211: '奉化市',
        200212: '其它区',
        200301: '鹿城区',
        200302: '龙湾区',
        200303: '瓯海区',
        200304: '洞头县',
        200305: '永嘉县',
        200306: '平阳县',
        200307: '苍南县',
        200308: '文成县',
        200309: '泰顺县',
        200310: '瑞安市',
        200311: '乐清市',
        200312: '其它区',
        200401: '南湖区',
        200402: '秀洲区',
        200403: '嘉善县',
        200404: '海盐县',
        200405: '海宁市',
        200406: '平湖市',
        200407: '桐乡市',
        200408: '其它区',
        200501: '吴兴区',
        200502: '南浔区',
        200503: '德清县',
        200504: '长兴县',
        200505: '安吉县',
        200506: '其它区',
        200601: '越城区',
        200602: '柯桥区',
        200603: '新昌县',
        200604: '诸暨市',
        200605: '上虞区',
        200606: '嵊州市',
        200607: '其它区',
        200701: '婺城区',
        200702: '金东区',
        200703: '武义县',
        200704: '浦江县',
        200705: '磐安县',
        200706: '兰溪市',
        200707: '义乌市',
        200708: '东阳市',
        200709: '永康市',
        200710: '其它区',
        200801: '柯城区',
        200802: '衢江区',
        200803: '常山县',
        200804: '开化县',
        200805: '龙游县',
        200806: '江山市',
        200807: '其它区',
        200901: '定海区',
        200902: '普陀区',
        200903: '岱山县',
        200904: '嵊泗县',
        200905: '其它区',
        201001: '椒江区',
        201002: '黄岩区',
        201003: '路桥区',
        201004: '玉环县',
        201005: '三门县',
        201006: '天台县',
        201007: '仙居县',
        201008: '温岭市',
        201009: '临海市',
        201010: '其它区',
        201101: '莲都区',
        201102: '青田县',
        201103: '缙云县',
        201104: '遂昌县',
        201105: '松阳县',
        201106: '云和县',
        201107: '庆元县',
        201108: '景宁畲族自治县',
        201109: '龙泉市',
        201110: '其它区',
        210101: '瑶海区',
        210102: '庐阳区',
        210103: '蜀山区',
        210104: '包河区',
        210105: '长丰县',
        210106: '肥东县',
        210107: '肥西县',
        210108: '高新区',
        210109: '中区',
        210110: '其它区',
        210111: '巢湖市',
        210112: '居巢区',
        210113: '庐江县',
        210201: '镜湖区',
        210202: '弋江区',
        210203: '鸠江区',
        210204: '三山区',
        210205: '芜湖县',
        210206: '繁昌县',
        210207: '南陵县',
        210208: '其它区',
        210209: '无为县',
        210301: '龙子湖区',
        210302: '蚌山区',
        210303: '禹会区',
        210304: '淮上区',
        210305: '怀远县',
        210306: '五河县',
        210307: '固镇县',
        210308: '其它区',
        210401: '大通区',
        210402: '田家庵区',
        210403: '谢家集区',
        210404: '八公山区',
        210405: '潘集区',
        210406: '凤台县',
        210407: '其它区',
        210501: '金家庄区',
        210502: '花山区',
        210503: '雨山区',
        210504: '博望区',
        210505: '当涂县',
        210506: '其它区',
        210507: '含山县',
        210508: '和县',
        210601: '杜集区',
        210602: '相山区',
        210603: '烈山区',
        210604: '濉溪县',
        210605: '其它区',
        210701: '铜官山区',
        210702: '狮子山区',
        210703: '郊区',
        210704: '铜陵县',
        210705: '其它区',
        210801: '迎江区',
        210802: '大观区',
        210803: '宜秀区',
        210804: '怀宁县',
        210805: '枞阳县',
        210806: '潜山县',
        210807: '太湖县',
        210808: '宿松县',
        210809: '望江县',
        210810: '岳西县',
        210811: '桐城市',
        210812: '其它区',
        210901: '屯溪区',
        210902: '黄山区',
        210903: '徽州区',
        210904: '歙县',
        210905: '休宁县',
        210906: '黟县',
        210907: '祁门县',
        210908: '其它区',
        211001: '琅琊区',
        211002: '南谯区',
        211003: '来安县',
        211004: '全椒县',
        211005: '定远县',
        211006: '凤阳县',
        211007: '天长市',
        211008: '明光市',
        211009: '其它区',
        211101: '颍州区',
        211102: '颍东区',
        211103: '颍泉区',
        211104: '临泉县',
        211105: '太和县',
        211106: '阜南县',
        211107: '颍上县',
        211108: '界首市',
        211109: '其它区',
        211201: '砀山县',
        211202: '萧县',
        211203: '灵璧县',
        211204: '泗县',
        211205: '其它区',
        211301: '金安区',
        211302: '裕安区',
        211303: '寿县',
        211304: '霍邱县',
        211305: '舒城县',
        211306: '金寨县',
        211307: '霍山县',
        211308: '其它区',
        211401: '谯城区',
        211402: '涡阳县',
        211403: '蒙城县',
        211404: '利辛县',
        211405: '其它区',
        211501: '贵池区',
        211502: '东至县',
        211503: '石台县',
        211504: '青阳县',
        211505: '其它区',
        211601: '宣州区',
        211602: '郎溪县',
        211603: '广德县',
        211604: '泾县',
        211605: '绩溪县',
        211606: '旌德县',
        211607: '宁国市',
        211608: '其它区',
        220101: '鼓楼区',
        220102: '台江区',
        220103: '仓山区',
        220104: '马尾区',
        220105: '晋安区',
        220106: '闽侯县',
        220107: '连江县',
        220108: '罗源县',
        220109: '闽清县',
        220110: '永泰县',
        220111: '平潭县',
        220112: '福清市',
        220113: '长乐市',
        220114: '其它区',
        220201: '思明区',
        220202: '海沧区',
        220203: '湖里区',
        220204: '集美区',
        220205: '同安区',
        220206: '翔安区',
        220207: '其它区',
        220301: '城厢区',
        220302: '涵江区',
        220303: '荔城区',
        220304: '秀屿区',
        220305: '仙游县',
        220306: '其它区',
        220401: '梅列区',
        220402: '三元区',
        220403: '明溪县',
        220404: '清流县',
        220405: '宁化县',
        220406: '大田县',
        220407: '尤溪县',
        220408: '沙县',
        220409: '将乐县',
        220410: '泰宁县',
        220411: '建宁县',
        220412: '永安市',
        220413: '其它区',
        220501: '鲤城区',
        220502: '丰泽区',
        220503: '洛江区',
        220504: '泉港区',
        220505: '惠安县',
        220506: '安溪县',
        220507: '永春县',
        220508: '德化县',
        220509: '金门县',
        220510: '石狮市',
        220511: '晋江市',
        220512: '南安市',
        220513: '其它区',
        220601: '芗城区',
        220602: '龙文区',
        220603: '云霄县',
        220604: '漳浦县',
        220605: '诏安县',
        220606: '长泰县',
        220607: '东山县',
        220608: '南靖县',
        220609: '平和县',
        220610: '华安县',
        220611: '龙海市',
        220612: '其它区',
        220701: '延平区',
        220702: '顺昌县',
        220703: '浦城县',
        220704: '光泽县',
        220705: '松溪县',
        220706: '政和县',
        220707: '邵武市',
        220708: '武夷山市',
        220709: '建瓯市',
        220710: '建阳区',
        220711: '其它区',
        220801: '新罗区',
        220802: '长汀县',
        220803: '永定区',
        220804: '上杭县',
        220805: '武平县',
        220806: '连城县',
        220807: '漳平市',
        220808: '其它区',
        220901: '蕉城区',
        220902: '霞浦县',
        220903: '古田县',
        220904: '屏南县',
        220905: '寿宁县',
        220906: '周宁县',
        220907: '柘荣县',
        220908: '福安市',
        220909: '福鼎市',
        220910: '其它区',
        230101: '东湖区',
        230102: '西湖区',
        230103: '青云谱区',
        230104: '湾里区',
        230105: '青山湖区',
        230106: '南昌县',
        230107: '新建县',
        230108: '安义县',
        230109: '进贤县',
        230110: '红谷滩新区',
        230111: '经济技术开发区',
        230112: '昌北区',
        230113: '其它区',
        230201: '昌江区',
        230202: '珠山区',
        230203: '浮梁县',
        230204: '乐平市',
        230205: '其它区',
        230301: '安源区',
        230302: '湘东区',
        230303: '莲花县',
        230304: '上栗县',
        230305: '芦溪县',
        230306: '其它区',
        230401: '庐山区',
        230402: '浔阳区',
        230403: '九江县',
        230404: '武宁县',
        230405: '修水县',
        230406: '永修县',
        230407: '德安县',
        230408: '星子县',
        230409: '都昌县',
        230410: '湖口县',
        230411: '彭泽县',
        230412: '瑞昌市',
        230413: '其它区',
        230414: '共青城市',
        230501: '渝水区',
        230502: '分宜县',
        230503: '其它区',
        230601: '月湖区',
        230602: '余江县',
        230603: '贵溪市',
        230604: '其它区',
        230701: '章贡区',
        230702: '赣县',
        230703: '信丰县',
        230704: '大余县',
        230705: '上犹县',
        230706: '崇义县',
        230707: '安远县',
        230708: '龙南县',
        230709: '定南县',
        230710: '全南县',
        230711: '宁都县',
        230712: '于都县',
        230713: '兴国县',
        230714: '会昌县',
        230715: '寻乌县',
        230716: '石城县',
        230717: '黄金区',
        230718: '瑞金市',
        230719: '南康区',
        230720: '其它区',
        230801: '吉州区',
        230802: '青原区',
        230803: '吉安县',
        230804: '吉水县',
        230805: '峡江县',
        230806: '新干县',
        230807: '永丰县',
        230808: '泰和县',
        230809: '遂川县',
        230810: '万安县',
        230811: '安福县',
        230812: '永新县',
        230813: '井冈山市',
        230814: '其它区',
        230901: '袁州区',
        230902: '奉新县',
        230903: '万载县',
        230904: '上高县',
        230905: '宜丰县',
        230906: '靖安县',
        230907: '铜鼓县',
        230908: '丰城市',
        230909: '樟树市',
        230910: '高安市',
        230911: '其它区',
        231001: '临川区',
        231002: '南城县',
        231003: '黎川县',
        231004: '南丰县',
        231005: '崇仁县',
        231006: '乐安县',
        231007: '宜黄县',
        231008: '金溪县',
        231009: '资溪县',
        231010: '东乡县',
        231011: '广昌县',
        231012: '其它区',
        231101: '信州区',
        231102: '上饶县',
        231103: '广丰区',
        231104: '玉山县',
        231105: '铅山县',
        231106: '横峰县',
        231107: '弋阳县',
        231108: '余干县',
        231109: '鄱阳县',
        231110: '万年县',
        231111: '婺源县',
        231112: '德兴市',
        231113: '其它区',
        240101: '历下区',
        240102: '市中区',
        240103: '槐荫区',
        240104: '天桥区',
        240105: '历城区',
        240106: '长清区',
        240107: '平阴县',
        240108: '济阳县',
        240109: '商河县',
        240110: '章丘市',
        240111: '其它区',
        240201: '市南区',
        240202: '市北区',
        240203: '四方区',
        240204: '黄岛区',
        240205: '崂山区',
        240206: '李沧区',
        240207: '城阳区',
        240208: '开发区',
        240209: '胶州市',
        240210: '即墨市',
        240211: '平度市',
        240212: '胶南市',
        240213: '莱西市',
        240214: '其它区',
        240301: '淄川区',
        240302: '张店区',
        240303: '博山区',
        240304: '临淄区',
        240305: '周村区',
        240306: '桓台县',
        240307: '高青县',
        240308: '沂源县',
        240309: '其它区',
        240401: '市中区',
        240402: '薛城区',
        240403: '峄城区',
        240404: '台儿庄区',
        240405: '山亭区',
        240406: '滕州市',
        240407: '其它区',
        240501: '东营区',
        240502: '河口区',
        240503: '垦利县',
        240504: '利津县',
        240505: '广饶县',
        240506: '西城区',
        240507: '东城区',
        240508: '其它区',
        240601: '芝罘区',
        240602: '福山区',
        240603: '牟平区',
        240604: '莱山区',
        240605: '长岛县',
        240606: '龙口市',
        240607: '莱阳市',
        240608: '莱州市',
        240609: '蓬莱市',
        240610: '招远市',
        240611: '栖霞市',
        240612: '海阳市',
        240613: '其它区',
        240701: '潍城区',
        240702: '寒亭区',
        240703: '坊子区',
        240704: '奎文区',
        240705: '临朐县',
        240706: '昌乐县',
        240707: '开发区',
        240708: '青州市',
        240709: '诸城市',
        240710: '寿光市',
        240711: '安丘市',
        240712: '高密市',
        240713: '昌邑市',
        240714: '其它区',
        240801: '市中区',
        240802: '任城区',
        240803: '微山县',
        240804: '鱼台县',
        240805: '金乡县',
        240806: '嘉祥县',
        240807: '汶上县',
        240808: '泗水县',
        240809: '梁山县',
        240810: '曲阜市',
        240811: '兖州区',
        240812: '邹城市',
        240813: '其它区',
        240901: '泰山区',
        240902: '岱岳区',
        240903: '宁阳县',
        240904: '东平县',
        240905: '新泰市',
        240906: '肥城市',
        240907: '其它区',
        241001: '环翠区',
        241002: '文登区',
        241003: '荣成市',
        241004: '乳山市',
        241005: '其它区',
        241101: '东港区',
        241102: '岚山区',
        241103: '五莲县',
        241104: '莒县',
        241105: '其它区',
        241201: '莱城区',
        241202: '钢城区',
        241203: '其它区',
        241301: '兰山区',
        241302: '罗庄区',
        241303: '河东区',
        241304: '沂南县',
        241305: '郯城县',
        241306: '沂水县',
        241307: '兰陵县',
        241308: '费县',
        241309: '平邑县',
        241310: '莒南县',
        241311: '蒙阴县',
        241312: '临沭县',
        241313: '其它区',
        241401: '德城区',
        241402: '陵城区',
        241403: '宁津县',
        241404: '庆云县',
        241405: '临邑县',
        241406: '齐河县',
        241407: '平原县',
        241408: '夏津县',
        241409: '武城县',
        241410: '开发区',
        241411: '乐陵市',
        241412: '禹城市',
        241413: '其它区',
        241501: '东昌府区',
        241502: '阳谷县',
        241503: '莘县',
        241504: '茌平县',
        241505: '东阿县',
        241506: '冠县',
        241507: '高唐县',
        241508: '临清市',
        241509: '其它区',
        241601: '滨城区',
        241602: '惠民县',
        241603: '阳信县',
        241604: '无棣县',
        241605: '沾化区',
        241606: '博兴县',
        241607: '邹平县',
        241608: '其它区',
        241701: '牡丹区',
        241702: '曹县',
        241703: '单县',
        241704: '成武县',
        241705: '巨野县',
        241706: '郓城县',
        241707: '鄄城县',
        241708: '定陶县',
        241709: '东明县',
        241710: '其它区',
        250101: '中原区',
        250102: '二七区',
        250103: '管城回族区',
        250104: '金水区',
        250105: '上街区',
        250106: '惠济区',
        250107: '中牟县',
        250108: '巩义市',
        250109: '荥阳市',
        250110: '新密市',
        250111: '新郑市',
        250112: '登封市',
        250113: '郑东新区',
        250114: '高新区',
        250115: '其它区',
        250201: '龙亭区',
        250202: '顺河回族区',
        250203: '鼓楼区',
        250204: '禹王台区',
        250205: '金明区',
        250206: '杞县',
        250207: '通许县',
        250208: '尉氏县',
        250209: '祥符区',
        250210: '兰考县',
        250211: '其它区',
        250301: '老城区',
        250302: '西工区',
        250303: '涧西区',
        250304: '吉利区',
        250305: '洛龙区',
        250306: '孟津县',
        250307: '新安县',
        250308: '栾川县',
        250309: '嵩县',
        250310: '汝阳县',
        250311: '宜阳县',
        250312: '洛宁县',
        250313: '伊川县',
        250314: '偃师市',
        250315: '高新区',
        250316: '其它区',
        250401: '新华区',
        250402: '卫东区',
        250403: '石龙区',
        250404: '湛河区',
        250405: '宝丰县',
        250406: '叶县',
        250407: '鲁山县',
        250408: '郏县',
        250409: '舞钢市',
        250410: '汝州市',
        250411: '其它区',
        250501: '文峰区',
        250502: '北关区',
        250503: '殷都区',
        250504: '龙安区',
        250505: '安阳县',
        250506: '汤阴县',
        250507: '滑县',
        250508: '内黄县',
        250509: '林州市',
        250510: '其它区',
        250601: '鹤山区',
        250602: '山城区',
        250603: '淇滨区',
        250604: '浚县',
        250605: '淇县',
        250606: '其它区',
        250701: '红旗区',
        250702: '卫滨区',
        250703: '凤泉区',
        250704: '牧野区',
        250705: '新乡县',
        250706: '获嘉县',
        250707: '原阳县',
        250708: '延津县',
        250709: '封丘县',
        250710: '长垣县',
        250711: '卫辉市',
        250712: '辉县市',
        250713: '其它区',
        250801: '解放区',
        250802: '中站区',
        250803: '马村区',
        250804: '山阳区',
        250805: '修武县',
        250806: '博爱县',
        250807: '武陟县',
        250808: '温县',
        250809: '沁阳市',
        250810: '孟州市',
        250811: '其它区',
        250901: '华龙区',
        250902: '清丰县',
        250903: '南乐县',
        250904: '范县',
        250905: '台前县',
        250906: '濮阳县',
        250907: '其它区',
        251001: '魏都区',
        251002: '许昌县',
        251003: '鄢陵县',
        251004: '襄城县',
        251005: '禹州市',
        251006: '长葛市',
        251007: '其它区',
        251101: '源汇区',
        251102: '郾城区',
        251103: '召陵区',
        251104: '舞阳县',
        251105: '临颍县',
        251106: '其它区',
        251201: '湖滨区',
        251202: '渑池县',
        251203: '陕州区',
        251204: '卢氏县',
        251205: '义马市',
        251206: '灵宝市',
        251207: '其它区',
        251301: '宛城区',
        251302: '卧龙区',
        251303: '南召县',
        251304: '方城县',
        251305: '西峡县',
        251306: '镇平县',
        251307: '内乡县',
        251308: '淅川县',
        251309: '社旗县',
        251310: '唐河县',
        251311: '新野县',
        251312: '桐柏县',
        251313: '邓州市',
        251314: '其它区',
        251401: '梁园区',
        251402: '睢阳区',
        251403: '民权县',
        251404: '睢县',
        251405: '宁陵县',
        251406: '柘城县',
        251407: '虞城县',
        251408: '夏邑县',
        251409: '永城市',
        251410: '其它区',
        251501: '平桥区',
        251502: '罗山县',
        251503: '光山县',
        251504: '新县',
        251505: '商城县',
        251506: '固始县',
        251507: '潢川县',
        251508: '淮滨县',
        251509: '息县',
        251510: '其它区',
        251601: '川汇区',
        251602: '扶沟县',
        251603: '西华县',
        251604: '商水县',
        251605: '沈丘县',
        251606: '郸城县',
        251607: '淮阳县',
        251608: '太康县',
        251609: '鹿邑县',
        251610: '项城市',
        251611: '其它区',
        251701: '驿城区',
        251702: '西平县',
        251703: '上蔡县',
        251704: '平舆县',
        251705: '正阳县',
        251706: '确山县',
        251707: '泌阳县',
        251708: '汝南县',
        251709: '遂平县',
        251710: '新蔡县',
        251711: '其它区',
        251801: '其它区',
        260101: '江岸区',
        260102: '江汉区',
        260103: '汉阳区',
        260104: '武昌区',
        260105: '青山区',
        260106: '洪山区',
        260107: '东西湖区',
        260108: '汉南区',
        260109: '蔡甸区',
        260110: '江夏区',
        260111: '黄陂区',
        260112: '新洲区',
        260113: '其它区',
        260201: '黄石港区',
        260202: '西塞山区',
        260203: '下陆区',
        260204: '铁山区',
        260205: '阳新县',
        260206: '大冶市',
        260207: '其它区',
        260301: '茅箭区',
        260302: '张湾区',
        260303: '郧阳区',
        260304: '郧西县',
        260305: '竹山县',
        260306: '竹溪县',
        260307: '房县',
        260308: '丹江口市',
        260309: '城区',
        260310: '其它区',
        260401: '西陵区',
        260402: '伍家岗区',
        260403: '点军区',
        260404: '夷陵区',
        260405: '远安县',
        260406: '兴山县',
        260407: '秭归县',
        260408: '长阳土家族自治县',
        260409: '五峰土家族自治县',
        260410: '葛洲坝区',
        260411: '开发区',
        260412: '宜都市',
        260413: '当阳市',
        260414: '枝江市',
        260415: '其它区',
        260501: '襄城区',
        260502: '樊城区',
        260503: '襄州区',
        260504: '南漳县',
        260505: '谷城县',
        260506: '保康县',
        260507: '老河口市',
        260508: '枣阳市',
        260509: '宜城市',
        260510: '其它区',
        260601: '梁子湖区',
        260602: '华容区',
        260603: '鄂城区',
        260604: '其它区',
        260701: '东宝区',
        260702: '掇刀区',
        260703: '京山县',
        260704: '沙洋县',
        260705: '钟祥市',
        260706: '其它区',
        260801: '孝南区',
        260802: '孝昌县',
        260803: '大悟县',
        260804: '云梦县',
        260805: '应城市',
        260806: '安陆市',
        260807: '汉川市',
        260808: '其它区',
        260901: '沙市区',
        260902: '荆州区',
        260903: '公安县',
        260904: '监利县',
        260905: '江陵县',
        260906: '石首市',
        260907: '洪湖市',
        260908: '松滋市',
        260909: '其它区',
        261001: '黄州区',
        261002: '团风县',
        261003: '红安县',
        261004: '罗田县',
        261005: '英山县',
        261006: '浠水县',
        261007: '蕲春县',
        261008: '黄梅县',
        261009: '麻城市',
        261010: '武穴市',
        261011: '其它区',
        261101: '咸安区',
        261102: '嘉鱼县',
        261103: '通城县',
        261104: '崇阳县',
        261105: '通山县',
        261106: '赤壁市',
        261107: '温泉城区',
        261108: '其它区',
        261201: '曾都区',
        261202: '随县',
        261203: '广水市',
        261204: '其它区',
        261301: '恩施市',
        261302: '利川市',
        261303: '建始县',
        261304: '巴东县',
        261305: '宣恩县',
        261306: '咸丰县',
        261307: '来凤县',
        261308: '鹤峰县',
        261309: '其它区',
        261401: '其它区',
        261501: '其它区',
        261601: '其它区',
        261701: '其它区',
        270101: '芙蓉区',
        270102: '天心区',
        270103: '岳麓区',
        270104: '开福区',
        270105: '雨花区',
        270106: '长沙县',
        270107: '望城区',
        270108: '宁乡县',
        270109: '浏阳市',
        270110: '其它区',
        270201: '荷塘区',
        270202: '芦淞区',
        270203: '石峰区',
        270204: '天元区',
        270205: '株洲县',
        270206: '攸县',
        270207: '茶陵县',
        270208: '炎陵县',
        270209: '醴陵市',
        270210: '其它区',
        270301: '雨湖区',
        270302: '岳塘区',
        270303: '湘潭县',
        270304: '湘乡市',
        270305: '韶山市',
        270306: '其它区',
        270401: '珠晖区',
        270402: '雁峰区',
        270403: '石鼓区',
        270404: '蒸湘区',
        270405: '南岳区',
        270406: '衡阳县',
        270407: '衡南县',
        270408: '衡山县',
        270409: '衡东县',
        270410: '祁东县',
        270411: '耒阳市',
        270412: '常宁市',
        270413: '其它区',
        270501: '双清区',
        270502: '大祥区',
        270503: '北塔区',
        270504: '邵东县',
        270505: '新邵县',
        270506: '邵阳县',
        270507: '隆回县',
        270508: '洞口县',
        270509: '绥宁县',
        270510: '新宁县',
        270511: '城步苗族自治县',
        270512: '武冈市',
        270513: '其它区',
        270601: '岳阳楼区',
        270602: '云溪区',
        270603: '君山区',
        270604: '岳阳县',
        270605: '华容县',
        270606: '湘阴县',
        270607: '平江县',
        270608: '汨罗市',
        270609: '临湘市',
        270610: '其它区',
        270701: '武陵区',
        270702: '鼎城区',
        270703: '安乡县',
        270704: '汉寿县',
        270705: '澧县',
        270706: '临澧县',
        270707: '桃源县',
        270708: '石门县',
        270709: '津市市',
        270710: '其它区',
        270801: '永定区',
        270802: '武陵源区',
        270803: '慈利县',
        270804: '桑植县',
        270805: '其它区',
        270901: '资阳区',
        270902: '赫山区',
        270903: '南县',
        270904: '桃江县',
        270905: '安化县',
        270906: '沅江市',
        270907: '其它区',
        271001: '北湖区',
        271002: '苏仙区',
        271003: '桂阳县',
        271004: '宜章县',
        271005: '永兴县',
        271006: '嘉禾县',
        271007: '临武县',
        271008: '汝城县',
        271009: '桂东县',
        271010: '安仁县',
        271011: '资兴市',
        271012: '其它区',
        271101: '零陵区',
        271102: '冷水滩区',
        271103: '祁阳县',
        271104: '东安县',
        271105: '双牌县',
        271106: '道县',
        271107: '江永县',
        271108: '宁远县',
        271109: '蓝山县',
        271110: '新田县',
        271111: '江华瑶族自治县',
        271112: '其它区',
        271201: '鹤城区',
        271202: '中方县',
        271203: '沅陵县',
        271204: '辰溪县',
        271205: '溆浦县',
        271206: '会同县',
        271207: '麻阳苗族自治县',
        271208: '新晃侗族自治县',
        271209: '芷江侗族自治县',
        271210: '靖州苗族侗族自治县',
        271211: '通道侗族自治县',
        271212: '洪江市',
        271213: '其它区',
        271301: '娄星区',
        271302: '双峰县',
        271303: '新化县',
        271304: '冷水江市',
        271305: '涟源市',
        271306: '其它区',
        271401: '吉首市',
        271402: '泸溪县',
        271403: '凤凰县',
        271404: '花垣县',
        271405: '保靖县',
        271406: '古丈县',
        271407: '永顺县',
        271408: '龙山县',
        271409: '其它区',
        280101: '荔湾区',
        280102: '越秀区',
        280103: '海珠区',
        280104: '天河区',
        280105: '白云区',
        280106: '黄埔区',
        280107: '番禺区',
        280108: '花都区',
        280109: '南沙区',
        280110: '萝岗区',
        280111: '增城区',
        280112: '从化区',
        280113: '东山区',
        280114: '其它区',
        280201: '武江区',
        280202: '浈江区',
        280203: '曲江区',
        280204: '始兴县',
        280205: '仁化县',
        280206: '翁源县',
        280207: '乳源瑶族自治县',
        280208: '新丰县',
        280209: '乐昌市',
        280210: '南雄市',
        280211: '其它区',
        280301: '罗湖区',
        280302: '福田区',
        280303: '南山区',
        280304: '宝安区',
        280305: '龙岗区',
        280306: '盐田区',
        280307: '其它区',
        280308: '光明新区',
        280309: '坪山新区',
        280310: '大鹏新区',
        280311: '龙华新区',
        280401: '香洲区',
        280402: '斗门区',
        280403: '金湾区',
        280404: '金唐区',
        280405: '南湾区',
        280406: '其它区',
        280501: '龙湖区',
        280502: '金平区',
        280503: '濠江区',
        280504: '潮阳区',
        280505: '潮南区',
        280506: '澄海区',
        280507: '南澳县',
        280508: '其它区',
        280601: '禅城区',
        280602: '南海区',
        280603: '顺德区',
        280604: '三水区',
        280605: '高明区',
        280606: '其它区',
        280701: '蓬江区',
        280702: '江海区',
        280703: '新会区',
        280704: '台山市',
        280705: '开平市',
        280706: '鹤山市',
        280707: '恩平市',
        280708: '其它区',
        280801: '赤坎区',
        280802: '霞山区',
        280803: '坡头区',
        280804: '麻章区',
        280805: '遂溪县',
        280806: '徐闻县',
        280807: '廉江市',
        280808: '雷州市',
        280809: '吴川市',
        280810: '其它区',
        280901: '茂南区',
        280902: '电白区',
        280903: '电白县',
        280904: '高州市',
        280905: '化州市',
        280906: '信宜市',
        280907: '其它区',
        281001: '端州区',
        281002: '鼎湖区',
        281003: '广宁县',
        281004: '怀集县',
        281005: '封开县',
        281006: '德庆县',
        281007: '高要市',
        281008: '四会市',
        281009: '其它区',
        281101: '惠城区',
        281102: '惠阳区',
        281103: '博罗县',
        281104: '惠东县',
        281105: '龙门县',
        281106: '其它区',
        281201: '梅江区',
        281202: '梅县区',
        281203: '大埔县',
        281204: '丰顺县',
        281205: '五华县',
        281206: '平远县',
        281207: '蕉岭县',
        281208: '兴宁市',
        281209: '其它区',
        281301: '城区',
        281302: '海丰县',
        281303: '陆河县',
        281304: '陆丰市',
        281305: '其它区',
        281401: '源城区',
        281402: '紫金县',
        281403: '龙川县',
        281404: '连平县',
        281405: '和平县',
        281406: '东源县',
        281407: '其它区',
        281501: '江城区',
        281502: '阳西县',
        281503: '阳东区',
        281504: '阳春市',
        281505: '其它区',
        281601: '清城区',
        281602: '佛冈县',
        281603: '阳山县',
        281604: '连山壮族瑶族自治县',
        281605: '连南瑶族自治县',
        281606: '清新区',
        281607: '英德市',
        281608: '连州市',
        281609: '其它区',
        281701: '东城街道',
        281702: '南城街道',
        281703: '万江街道',
        281704: '莞城街道',
        281705: '石碣镇',
        281706: '石龙镇',
        281707: '茶山镇',
        281708: '石排镇',
        281709: '企石镇',
        281710: '横沥镇',
        281711: '桥头镇',
        281712: '谢岗镇',
        281713: '东坑镇',
        281714: '常平镇',
        281715: '寮步镇',
        281716: '樟木头镇',
        281717: '大朗镇',
        281718: '黄江镇',
        281719: '清溪镇',
        281720: '塘厦镇',
        281721: '凤岗镇',
        281722: '大岭山镇',
        281723: '长安镇',
        281724: '虎门镇',
        281725: '厚街镇',
        281726: '沙田镇',
        281727: '道滘镇',
        281728: '洪梅镇',
        281729: '麻涌镇',
        281730: '望牛墩镇',
        281731: '中堂镇',
        281732: '高埗镇',
        281733: '东莞生态园',
        281734: '松山湖管委会',
        281735: '虎门港管委会',
        281736: '其它区',
        281801: '石岐区街道',
        281802: '东区街道',
        281803: '西区街道',
        281804: '南区街道',
        281805: '五桂山街道',
        281806: '小榄镇',
        281807: '黄圃镇',
        281808: '民众镇',
        281809: '东凤镇',
        281810: '东升镇',
        281811: '古镇镇',
        281812: '沙溪镇',
        281813: '坦洲镇',
        281814: '港口镇',
        281815: '三角镇',
        281816: '横栏镇',
        281817: '南头镇',
        281818: '阜沙镇',
        281819: '南朗镇',
        281820: '三乡镇',
        281821: '板芙镇',
        281822: '大涌镇',
        281823: '神湾镇',
        281824: '火炬开发区街道',
        281825: '其它区',
        281901: '东沙群岛',
        281902: '其它区',
        282001: '湘桥区',
        282002: '潮安区',
        282003: '饶平县',
        282004: '枫溪区',
        282005: '其它区',
        282101: '榕城区',
        282102: '揭东区',
        282103: '揭西县',
        282104: '惠来县',
        282105: '普宁市',
        282106: '东山区',
        282107: '其它区',
        282201: '云城区',
        282202: '新兴县',
        282203: '郁南县',
        282204: '云安区',
        282205: '罗定市',
        282206: '其它区',
        290101: '兴宁区',
        290102: '青秀区',
        290103: '江南区',
        290104: '西乡塘区',
        290105: '良庆区',
        290106: '邕宁区',
        290107: '武鸣区',
        290108: '隆安县',
        290109: '马山县',
        290110: '上林县',
        290111: '宾阳县',
        290112: '横县',
        290113: '其它区',
        290201: '城中区',
        290202: '鱼峰区',
        290203: '柳南区',
        290204: '柳北区',
        290205: '柳江县',
        290206: '柳城县',
        290207: '鹿寨县',
        290208: '融安县',
        290209: '融水苗族自治县',
        290210: '三江侗族自治县',
        290211: '其它区',
        290301: '秀峰区',
        290302: '叠彩区',
        290303: '象山区',
        290304: '七星区',
        290305: '雁山区',
        290306: '阳朔县',
        290307: '临桂区',
        290308: '灵川县',
        290309: '全州县',
        290310: '兴安县',
        290311: '永福县',
        290312: '灌阳县',
        290313: '龙胜各族自治县',
        290314: '资源县',
        290315: '平乐县',
        290316: '荔浦县',
        290317: '恭城瑶族自治县',
        290318: '其它区',
        290401: '万秀区',
        290402: '蝶山区',
        290403: '长洲区',
        290404: '龙圩区',
        290405: '苍梧县',
        290406: '藤县',
        290407: '蒙山县',
        290408: '岑溪市',
        290409: '其它区',
        290501: '海城区',
        290502: '银海区',
        290503: '铁山港区',
        290504: '合浦县',
        290505: '其它区',
        290601: '港口区',
        290602: '防城区',
        290603: '上思县',
        290604: '东兴市',
        290605: '其它区',
        290701: '钦南区',
        290702: '钦北区',
        290703: '灵山县',
        290704: '浦北县',
        290705: '其它区',
        290801: '港北区',
        290802: '港南区',
        290803: '覃塘区',
        290804: '平南县',
        290805: '桂平市',
        290806: '其它区',
        290901: '玉州区',
        290902: '福绵区',
        290903: '容县',
        290904: '陆川县',
        290905: '博白县',
        290906: '兴业县',
        290907: '北流市',
        290908: '其它区',
        291001: '右江区',
        291002: '田阳县',
        291003: '田东县',
        291004: '平果县',
        291005: '德保县',
        291006: '靖西县',
        291007: '那坡县',
        291008: '凌云县',
        291009: '乐业县',
        291010: '田林县',
        291011: '西林县',
        291012: '隆林各族自治县',
        291013: '其它区',
        291101: '八步区',
        291102: '平桂管理区',
        291103: '昭平县',
        291104: '钟山县',
        291105: '富川瑶族自治县',
        291106: '其它区',
        291201: '金城江区',
        291202: '南丹县',
        291203: '天峨县',
        291204: '凤山县',
        291205: '东兰县',
        291206: '罗城仫佬族自治县',
        291207: '环江毛南族自治县',
        291208: '巴马瑶族自治县',
        291209: '都安瑶族自治县',
        291210: '大化瑶族自治县',
        291211: '宜州市',
        291212: '其它区',
        291301: '兴宾区',
        291302: '忻城县',
        291303: '象州县',
        291304: '武宣县',
        291305: '金秀瑶族自治县',
        291306: '合山市',
        291307: '其它区',
        291401: '江州区',
        291402: '扶绥县',
        291403: '宁明县',
        291404: '龙州县',
        291405: '大新县',
        291406: '天等县',
        291407: '凭祥市',
        291408: '其它区',
        300101: '秀英区',
        300102: '龙华区',
        300103: '琼山区',
        300104: '美兰区',
        300105: '其它区',
        300201: '吉阳区',
        300202: '天涯区',
        300203: '崖州区',
        300204: '海棠区',
        300205: '其它区',
        300301: '西沙群岛',
        300302: '南沙群岛',
        300303: '中沙群岛的岛礁及其海域',
        300304: '其它区',
        300401: '其它区',
        300501: '其它区',
        300601: '其它区',
        300701: '其它区',
        300801: '其它区',
        300901: '其它区',
        301001: '其它区',
        301101: '其它区',
        301201: '其它区',
        301301: '其它区',
        301401: '其它区',
        301501: '其它区',
        301601: '其它区',
        301701: '其它区',
        301801: '其它区',
        301901: '其它区',
        310101: '万州区',
        310102: '涪陵区',
        310103: '渝中区',
        310104: '大渡口区',
        310105: '江北区',
        310106: '沙坪坝区',
        310107: '九龙坡区',
        310108: '南岸区',
        310109: '北碚区',
        310110: '万盛区',
        310111: '双桥区',
        310112: '渝北区',
        310113: '巴南区',
        310114: '黔江区',
        310115: '长寿区',
        310116: '綦江区',
        310117: '潼南县',
        310118: '铜梁区',
        310119: '大足区',
        310120: '荣昌县',
        310121: '璧山区',
        310122: '梁平县',
        310123: '城口县',
        310124: '丰都县',
        310125: '垫江县',
        310126: '武隆县',
        310127: '忠县',
        310128: '开县',
        310129: '云阳县',
        310130: '奉节县',
        310131: '巫山县',
        310132: '巫溪县',
        310133: '石柱土家族自治县',
        310134: '秀山土家族苗族自治县',
        310135: '酉阳土家族苗族自治县',
        310136: '彭水苗族土家族自治县',
        310137: '江津区',
        310138: '合川区',
        310139: '永川区',
        310140: '南川区',
        310141: '其它区',
        320101: '锦江区',
        320102: '青羊区',
        320103: '金牛区',
        320104: '武侯区',
        320105: '成华区',
        320106: '龙泉驿区',
        320107: '青白江区',
        320108: '新都区',
        320109: '温江区',
        320110: '金堂县',
        320111: '双流县',
        320112: '郫县',
        320113: '大邑县',
        320114: '蒲江县',
        320115: '新津县',
        320116: '都江堰市',
        320117: '彭州市',
        320118: '邛崃市',
        320119: '崇州市',
        320120: '其它区',
        320201: '自流井区',
        320202: '贡井区',
        320203: '大安区',
        320204: '沿滩区',
        320205: '荣县',
        320206: '富顺县',
        320207: '其它区',
        320301: '东区',
        320302: '西区',
        320303: '仁和区',
        320304: '米易县',
        320305: '盐边县',
        320306: '其它区',
        320401: '江阳区',
        320402: '纳溪区',
        320403: '龙马潭区',
        320404: '泸县',
        320405: '合江县',
        320406: '叙永县',
        320407: '古蔺县',
        320408: '其它区',
        320501: '旌阳区',
        320502: '中江县',
        320503: '罗江县',
        320504: '广汉市',
        320505: '什邡市',
        320506: '绵竹市',
        320507: '其它区',
        320601: '涪城区',
        320602: '游仙区',
        320603: '三台县',
        320604: '盐亭县',
        320605: '安县',
        320606: '梓潼县',
        320607: '北川羌族自治县',
        320608: '平武县',
        320609: '高新区',
        320610: '江油市',
        320611: '其它区',
        320701: '利州区',
        320702: '昭化区',
        320703: '朝天区',
        320704: '旺苍县',
        320705: '青川县',
        320706: '剑阁县',
        320707: '苍溪县',
        320708: '其它区',
        320801: '船山区',
        320802: '安居区',
        320803: '蓬溪县',
        320804: '射洪县',
        320805: '大英县',
        320806: '其它区',
        320901: '市中区',
        320902: '东兴区',
        320903: '威远县',
        320904: '资中县',
        320905: '隆昌县',
        320906: '其它区',
        321001: '市中区',
        321002: '沙湾区',
        321003: '五通桥区',
        321004: '金口河区',
        321005: '犍为县',
        321006: '井研县',
        321007: '夹江县',
        321008: '沐川县',
        321009: '峨边彝族自治县',
        321010: '马边彝族自治县',
        321011: '峨眉山市',
        321012: '其它区',
        321101: '顺庆区',
        321102: '高坪区',
        321103: '嘉陵区',
        321104: '南部县',
        321105: '营山县',
        321106: '蓬安县',
        321107: '仪陇县',
        321108: '西充县',
        321109: '阆中市',
        321110: '其它区',
        321201: '东坡区',
        321202: '仁寿县',
        321203: '彭山区',
        321204: '洪雅县',
        321205: '丹棱县',
        321206: '青神县',
        321207: '其它区',
        321301: '翠屏区',
        321302: '宜宾县',
        321303: '南溪区',
        321304: '江安县',
        321305: '长宁县',
        321306: '高县',
        321307: '珙县',
        321308: '筠连县',
        321309: '兴文县',
        321310: '屏山县',
        321311: '其它区',
        321401: '广安区',
        321402: '前锋区',
        321403: '岳池县',
        321404: '武胜县',
        321405: '邻水县',
        321406: '华蓥市',
        321407: '市辖区',
        321408: '其它区',
        321501: '通川区',
        321502: '达川区',
        321503: '宣汉县',
        321504: '开江县',
        321505: '大竹县',
        321506: '渠县',
        321507: '万源市',
        321508: '其它区',
        321601: '雨城区',
        321602: '名山区',
        321603: '荥经县',
        321604: '汉源县',
        321605: '石棉县',
        321606: '天全县',
        321607: '芦山县',
        321608: '宝兴县',
        321609: '其它区',
        321701: '巴州区',
        321702: '恩阳区',
        321703: '通江县',
        321704: '南江县',
        321705: '平昌县',
        321706: '其它区',
        321801: '雁江区',
        321802: '安岳县',
        321803: '乐至县',
        321804: '简阳市',
        321805: '其它区',
        321901: '汶川县',
        321902: '理县',
        321903: '茂县',
        321904: '松潘县',
        321905: '九寨沟县',
        321906: '金川县',
        321907: '小金县',
        321908: '黑水县',
        321909: '马尔康县',
        321910: '壤塘县',
        321911: '阿坝县',
        321912: '若尔盖县',
        321913: '红原县',
        321914: '其它区',
        322001: '康定市',
        322002: '泸定县',
        322003: '丹巴县',
        322004: '九龙县',
        322005: '雅江县',
        322006: '道孚县',
        322007: '炉霍县',
        322008: '甘孜县',
        322009: '新龙县',
        322010: '德格县',
        322011: '白玉县',
        322012: '石渠县',
        322013: '色达县',
        322014: '理塘县',
        322015: '巴塘县',
        322016: '乡城县',
        322017: '稻城县',
        322018: '得荣县',
        322019: '其它区',
        322101: '西昌市',
        322102: '木里藏族自治县',
        322103: '盐源县',
        322104: '德昌县',
        322105: '会理县',
        322106: '会东县',
        322107: '宁南县',
        322108: '普格县',
        322109: '布拖县',
        322110: '金阳县',
        322111: '昭觉县',
        322112: '喜德县',
        322113: '冕宁县',
        322114: '越西县',
        322115: '甘洛县',
        322116: '美姑县',
        322117: '雷波县',
        322118: '其它区',
        330101: '南明区',
        330102: '云岩区',
        330103: '花溪区',
        330104: '乌当区',
        330105: '白云区',
        330106: '小河区',
        330107: '开阳县',
        330108: '息烽县',
        330109: '修文县',
        330110: '观山湖区',
        330111: '清镇市',
        330112: '其它区',
        330201: '钟山区',
        330202: '六枝特区',
        330203: '水城县',
        330204: '盘县',
        330205: '其它区',
        330301: '红花岗区',
        330302: '汇川区',
        330303: '遵义县',
        330304: '桐梓县',
        330305: '绥阳县',
        330306: '正安县',
        330307: '道真仡佬族苗族自治县',
        330308: '务川仡佬族苗族自治县',
        330309: '凤冈县',
        330310: '湄潭县',
        330311: '余庆县',
        330312: '习水县',
        330313: '赤水市',
        330314: '仁怀市',
        330315: '其它区',
        330401: '西秀区',
        330402: '平坝区',
        330403: '普定县',
        330404: '镇宁布依族苗族自治县',
        330405: '关岭布依族苗族自治县',
        330406: '紫云苗族布依族自治县',
        330407: '其它区',
        330501: '碧江区',
        330502: '江口县',
        330503: '玉屏侗族自治县',
        330504: '石阡县',
        330505: '思南县',
        330506: '印江土家族苗族自治县',
        330507: '德江县',
        330508: '沿河土家族自治县',
        330509: '松桃苗族自治县',
        330510: '万山区',
        330511: '其它区',
        330601: '兴义市',
        330602: '兴仁县',
        330603: '普安县',
        330604: '晴隆县',
        330605: '贞丰县',
        330606: '望谟县',
        330607: '册亨县',
        330608: '安龙县',
        330609: '其它区',
        330701: '七星关区',
        330702: '大方县',
        330703: '黔西县',
        330704: '金沙县',
        330705: '织金县',
        330706: '纳雍县',
        330707: '威宁彝族回族苗族自治县',
        330708: '赫章县',
        330709: '其它区',
        330801: '凯里市',
        330802: '黄平县',
        330803: '施秉县',
        330804: '三穗县',
        330805: '镇远县',
        330806: '岑巩县',
        330807: '天柱县',
        330808: '锦屏县',
        330809: '剑河县',
        330810: '台江县',
        330811: '黎平县',
        330812: '榕江县',
        330813: '从江县',
        330814: '雷山县',
        330815: '麻江县',
        330816: '丹寨县',
        330817: '其它区',
        330901: '都匀市',
        330902: '福泉市',
        330903: '荔波县',
        330904: '贵定县',
        330905: '瓮安县',
        330906: '独山县',
        330907: '平塘县',
        330908: '罗甸县',
        330909: '长顺县',
        330910: '龙里县',
        330911: '惠水县',
        330912: '三都水族自治县',
        330913: '其它区',
        340101: '五华区',
        340102: '盘龙区',
        340103: '官渡区',
        340104: '西山区',
        340105: '东川区',
        340106: '呈贡区',
        340107: '晋宁县',
        340108: '富民县',
        340109: '宜良县',
        340110: '石林彝族自治县',
        340111: '嵩明县',
        340112: '禄劝彝族苗族自治县',
        340113: '寻甸回族彝族自治县',
        340114: '安宁市',
        340115: '其它区',
        340201: '麒麟区',
        340202: '马龙县',
        340203: '陆良县',
        340204: '师宗县',
        340205: '罗平县',
        340206: '富源县',
        340207: '会泽县',
        340208: '沾益县',
        340209: '宣威市',
        340210: '其它区',
        340301: '红塔区',
        340302: '江川县',
        340303: '澄江县',
        340304: '通海县',
        340305: '华宁县',
        340306: '易门县',
        340307: '峨山彝族自治县',
        340308: '新平彝族傣族自治县',
        340309: '元江哈尼族彝族傣族自治县',
        340310: '其它区',
        340401: '隆阳区',
        340402: '施甸县',
        340403: '腾冲县',
        340404: '龙陵县',
        340405: '昌宁县',
        340406: '其它区',
        340501: '昭阳区',
        340502: '鲁甸县',
        340503: '巧家县',
        340504: '盐津县',
        340505: '大关县',
        340506: '永善县',
        340507: '绥江县',
        340508: '镇雄县',
        340509: '彝良县',
        340510: '威信县',
        340511: '水富县',
        340512: '其它区',
        340601: '古城区',
        340602: '玉龙纳西族自治县',
        340603: '永胜县',
        340604: '华坪县',
        340605: '宁蒗彝族自治县',
        340606: '其它区',
        340701: '思茅区',
        340702: '宁洱哈尼族彝族自治县',
        340703: '墨江哈尼族自治县',
        340704: '景东彝族自治县',
        340705: '景谷傣族彝族自治县',
        340706: '镇沅彝族哈尼族拉祜族自治县',
        340707: '江城哈尼族彝族自治县',
        340708: '孟连傣族拉祜族佤族自治县',
        340709: '澜沧拉祜族自治县',
        340710: '西盟佤族自治县',
        340711: '其它区',
        340801: '临翔区',
        340802: '凤庆县',
        340803: '云县',
        340804: '永德县',
        340805: '镇康县',
        340806: '双江拉祜族佤族布朗族傣族自治县',
        340807: '耿马傣族佤族自治县',
        340808: '沧源佤族自治县',
        340809: '其它区',
        340901: '楚雄市',
        340902: '双柏县',
        340903: '牟定县',
        340904: '南华县',
        340905: '姚安县',
        340906: '大姚县',
        340907: '永仁县',
        340908: '元谋县',
        340909: '武定县',
        340910: '禄丰县',
        340911: '其它区',
        341001: '个旧市',
        341002: '开远市',
        341003: '蒙自市',
        341004: '屏边苗族自治县',
        341005: '建水县',
        341006: '石屏县',
        341007: '弥勒市',
        341008: '泸西县',
        341009: '元阳县',
        341010: '红河县',
        341011: '金平苗族瑶族傣族自治县',
        341012: '绿春县',
        341013: '河口瑶族自治县',
        341014: '其它区',
        341101: '文山市',
        341102: '砚山县',
        341103: '西畴县',
        341104: '麻栗坡县',
        341105: '马关县',
        341106: '丘北县',
        341107: '广南县',
        341108: '富宁县',
        341109: '其它区',
        341201: '景洪市',
        341202: '勐海县',
        341203: '勐腊县',
        341204: '其它区',
        341301: '大理市',
        341302: '漾濞彝族自治县',
        341303: '祥云县',
        341304: '宾川县',
        341305: '弥渡县',
        341306: '南涧彝族自治县',
        341307: '巍山彝族回族自治县',
        341308: '永平县',
        341309: '云龙县',
        341310: '洱源县',
        341311: '剑川县',
        341312: '鹤庆县',
        341313: '其它区',
        341401: '瑞丽市',
        341402: '芒市',
        341403: '梁河县',
        341404: '盈江县',
        341405: '陇川县',
        341406: '其它区',
        341501: '泸水县',
        341502: '福贡县',
        341503: '贡山独龙族怒族自治县',
        341504: '兰坪白族普米族自治县',
        341505: '其它区',
        341601: '香格里拉市',
        341602: '德钦县',
        341603: '维西傈僳族自治县',
        341604: '其它区',
        350101: '城关区',
        350102: '林周县',
        350103: '当雄县',
        350104: '尼木县',
        350105: '曲水县',
        350106: '堆龙德庆县',
        350107: '达孜县',
        350108: '墨竹工卡县',
        350109: '其它区',
        350201: '卡若区',
        350202: '江达县',
        350203: '贡觉县',
        350204: '类乌齐县',
        350205: '丁青县',
        350206: '察雅县',
        350207: '八宿县',
        350208: '左贡县',
        350209: '芒康县',
        350210: '洛隆县',
        350211: '边坝县',
        350212: '其它区',
        350301: '乃东县',
        350302: '扎囊县',
        350303: '贡嘎县',
        350304: '桑日县',
        350305: '琼结县',
        350306: '曲松县',
        350307: '措美县',
        350308: '洛扎县',
        350309: '加查县',
        350310: '隆子县',
        350311: '错那县',
        350312: '浪卡子县',
        350313: '其它区',
        350401: '桑珠孜区',
        350402: '南木林县',
        350403: '江孜县',
        350404: '定日县',
        350405: '萨迦县',
        350406: '拉孜县',
        350407: '昂仁县',
        350408: '谢通门县',
        350409: '白朗县',
        350410: '仁布县',
        350411: '康马县',
        350412: '定结县',
        350413: '仲巴县',
        350414: '亚东县',
        350415: '吉隆县',
        350416: '聂拉木县',
        350417: '萨嘎县',
        350418: '岗巴县',
        350419: '其它区',
        350501: '那曲县',
        350502: '嘉黎县',
        350503: '比如县',
        350504: '聂荣县',
        350505: '安多县',
        350506: '申扎县',
        350507: '索县',
        350508: '班戈县',
        350509: '巴青县',
        350510: '尼玛县',
        350511: '其它区',
        350512: '双湖县',
        350601: '普兰县',
        350602: '札达县',
        350603: '噶尔县',
        350604: '日土县',
        350605: '革吉县',
        350606: '改则县',
        350607: '措勤县',
        350608: '其它区',
        350701: '巴宜区',
        350702: '工布江达县',
        350703: '米林县',
        350704: '墨脱县',
        350705: '波密县',
        350706: '察隅县',
        350707: '朗县',
        350708: '其它区',
        360101: '新城区',
        360102: '碑林区',
        360103: '莲湖区',
        360104: '灞桥区',
        360105: '未央区',
        360106: '雁塔区',
        360107: '阎良区',
        360108: '临潼区',
        360109: '长安区',
        360110: '蓝田县',
        360111: '周至县',
        360112: '户县',
        360113: '高陵区',
        360114: '其它区',
        360201: '王益区',
        360202: '印台区',
        360203: '耀州区',
        360204: '宜君县',
        360205: '其它区',
        360301: '渭滨区',
        360302: '金台区',
        360303: '陈仓区',
        360304: '凤翔县',
        360305: '岐山县',
        360306: '扶风县',
        360307: '眉县',
        360308: '陇县',
        360309: '千阳县',
        360310: '麟游县',
        360311: '凤县',
        360312: '太白县',
        360313: '其它区',
        360401: '秦都区',
        360402: '杨陵区',
        360403: '渭城区',
        360404: '三原县',
        360405: '泾阳县',
        360406: '乾县',
        360407: '礼泉县',
        360408: '永寿县',
        360409: '彬县',
        360410: '长武县',
        360411: '旬邑县',
        360412: '淳化县',
        360413: '武功县',
        360414: '兴平市',
        360415: '其它区',
        360501: '临渭区',
        360502: '华县',
        360503: '潼关县',
        360504: '大荔县',
        360505: '合阳县',
        360506: '澄城县',
        360507: '蒲城县',
        360508: '白水县',
        360509: '富平县',
        360510: '韩城市',
        360511: '华阴市',
        360512: '其它区',
        360601: '宝塔区',
        360602: '延长县',
        360603: '延川县',
        360604: '子长县',
        360605: '安塞县',
        360606: '志丹县',
        360607: '吴起县',
        360608: '甘泉县',
        360609: '富县',
        360610: '洛川县',
        360611: '宜川县',
        360612: '黄龙县',
        360613: '黄陵县',
        360614: '其它区',
        360701: '汉台区',
        360702: '南郑县',
        360703: '城固县',
        360704: '洋县',
        360705: '西乡县',
        360706: '勉县',
        360707: '宁强县',
        360708: '略阳县',
        360709: '镇巴县',
        360710: '留坝县',
        360711: '佛坪县',
        360712: '其它区',
        360801: '榆阳区',
        360802: '神木县',
        360803: '府谷县',
        360804: '横山县',
        360805: '靖边县',
        360806: '定边县',
        360807: '绥德县',
        360808: '米脂县',
        360809: '佳县',
        360810: '吴堡县',
        360811: '清涧县',
        360812: '子洲县',
        360813: '其它区',
        360901: '汉滨区',
        360902: '汉阴县',
        360903: '石泉县',
        360904: '宁陕县',
        360905: '紫阳县',
        360906: '岚皋县',
        360907: '平利县',
        360908: '镇坪县',
        360909: '旬阳县',
        360910: '白河县',
        360911: '其它区',
        361001: '商州区',
        361002: '洛南县',
        361003: '丹凤县',
        361004: '商南县',
        361005: '山阳县',
        361006: '镇安县',
        361007: '柞水县',
        361008: '其它区',
        370101: '城关区',
        370102: '七里河区',
        370103: '西固区',
        370104: '安宁区',
        370105: '红古区',
        370106: '永登县',
        370107: '皋兰县',
        370108: '榆中县',
        370109: '其它区',
        370201: '新城镇',
        370202: '峪泉镇',
        370203: '文殊镇',
        370204: '雄关区',
        370205: '镜铁区',
        370206: '长城区',
        370207: '其它区',
        370301: '金川区',
        370302: '永昌县',
        370303: '其它区',
        370401: '白银区',
        370402: '平川区',
        370403: '靖远县',
        370404: '会宁县',
        370405: '景泰县',
        370406: '其它区',
        370501: '秦州区',
        370502: '麦积区',
        370503: '清水县',
        370504: '秦安县',
        370505: '甘谷县',
        370506: '武山县',
        370507: '张家川回族自治县',
        370508: '其它区',
        370601: '凉州区',
        370602: '民勤县',
        370603: '古浪县',
        370604: '天祝藏族自治县',
        370605: '其它区',
        370701: '甘州区',
        370702: '肃南裕固族自治县',
        370703: '民乐县',
        370704: '临泽县',
        370705: '高台县',
        370706: '山丹县',
        370707: '其它区',
        370801: '崆峒区',
        370802: '泾川县',
        370803: '灵台县',
        370804: '崇信县',
        370805: '华亭县',
        370806: '庄浪县',
        370807: '静宁县',
        370808: '其它区',
        370901: '肃州区',
        370902: '金塔县',
        370903: '瓜州县',
        370904: '肃北蒙古族自治县',
        370905: '阿克塞哈萨克族自治县',
        370906: '玉门市',
        370907: '敦煌市',
        370908: '其它区',
        371001: '西峰区',
        371002: '庆城县',
        371003: '环县',
        371004: '华池县',
        371005: '合水县',
        371006: '正宁县',
        371007: '宁县',
        371008: '镇原县',
        371009: '其它区',
        371101: '安定区',
        371102: '通渭县',
        371103: '陇西县',
        371104: '渭源县',
        371105: '临洮县',
        371106: '漳县',
        371107: '岷县',
        371108: '其它区',
        371201: '武都区',
        371202: '成县',
        371203: '文县',
        371204: '宕昌县',
        371205: '康县',
        371206: '西和县',
        371207: '礼县',
        371208: '徽县',
        371209: '两当县',
        371210: '其它区',
        371301: '临夏市',
        371302: '临夏县',
        371303: '康乐县',
        371304: '永靖县',
        371305: '广河县',
        371306: '和政县',
        371307: '东乡族自治县',
        371308: '积石山保安族东乡族撒拉族自治县',
        371309: '其它区',
        371401: '合作市',
        371402: '临潭县',
        371403: '卓尼县',
        371404: '舟曲县',
        371405: '迭部县',
        371406: '玛曲县',
        371407: '碌曲县',
        371408: '夏河县',
        371409: '其它区',
        380101: '城东区',
        380102: '城中区',
        380103: '城西区',
        380104: '城北区',
        380105: '大通回族土族自治县',
        380106: '湟中县',
        380107: '湟源县',
        380108: '其它区',
        380201: '平安区',
        380202: '民和回族土族自治县',
        380203: '乐都区',
        380204: '互助土族自治县',
        380205: '化隆回族自治县',
        380206: '循化撒拉族自治县',
        380207: '其它区',
        380301: '门源回族自治县',
        380302: '祁连县',
        380303: '海晏县',
        380304: '刚察县',
        380305: '其它区',
        380401: '同仁县',
        380402: '尖扎县',
        380403: '泽库县',
        380404: '河南蒙古族自治县',
        380405: '其它区',
        380501: '共和县',
        380502: '同德县',
        380503: '贵德县',
        380504: '兴海县',
        380505: '贵南县',
        380506: '其它区',
        380601: '玛沁县',
        380602: '班玛县',
        380603: '甘德县',
        380604: '达日县',
        380605: '久治县',
        380606: '玛多县',
        380607: '其它区',
        380701: '玉树市',
        380702: '杂多县',
        380703: '称多县',
        380704: '治多县',
        380705: '囊谦县',
        380706: '曲麻莱县',
        380707: '其它区',
        380801: '格尔木市',
        380802: '德令哈市',
        380803: '乌兰县',
        380804: '都兰县',
        380805: '天峻县',
        380806: '其它区',
        390101: '兴庆区',
        390102: '西夏区',
        390103: '金凤区',
        390104: '永宁县',
        390105: '贺兰县',
        390106: '灵武市',
        390107: '其它区',
        390201: '大武口区',
        390202: '惠农区',
        390203: '平罗县',
        390204: '其它区',
        390301: '利通区',
        390302: '红寺堡区',
        390303: '盐池县',
        390304: '同心县',
        390305: '青铜峡市',
        390306: '其它区',
        390401: '原州区',
        390402: '西吉县',
        390403: '隆德县',
        390404: '泾源县',
        390405: '彭阳县',
        390406: '其它区',
        390501: '沙坡头区',
        390502: '中宁县',
        390503: '海原县',
        390504: '其它区',
        400101: '天山区',
        400102: '沙依巴克区',
        400103: '新市区',
        400104: '水磨沟区',
        400105: '头屯河区',
        400106: '达坂城区',
        400107: '东山区',
        400108: '米东区',
        400109: '乌鲁木齐县',
        400110: '其它区',
        400201: '独山子区',
        400202: '克拉玛依区',
        400203: '白碱滩区',
        400204: '乌尔禾区',
        400205: '其它区',
        400301: '高昌区',
        400302: '鄯善县',
        400303: '托克逊县',
        400304: '其它区',
        400401: '哈密市',
        400402: '巴里坤哈萨克自治县',
        400403: '伊吾县',
        400404: '其它区',
        400501: '昌吉市',
        400502: '阜康市',
        400503: '米泉市',
        400504: '呼图壁县',
        400505: '玛纳斯县',
        400506: '奇台县',
        400507: '吉木萨尔县',
        400508: '木垒哈萨克自治县',
        400509: '其它区',
        400601: '博乐市',
        400602: '阿拉山口市',
        400603: '精河县',
        400604: '温泉县',
        400605: '其它区',
        400701: '库尔勒市',
        400702: '轮台县',
        400703: '尉犁县',
        400704: '若羌县',
        400705: '且末县',
        400706: '焉耆回族自治县',
        400707: '和静县',
        400708: '和硕县',
        400709: '博湖县',
        400710: '其它区',
        400801: '阿克苏市',
        400802: '温宿县',
        400803: '库车县',
        400804: '沙雅县',
        400805: '新和县',
        400806: '拜城县',
        400807: '乌什县',
        400808: '阿瓦提县',
        400809: '柯坪县',
        400810: '其它区',
        400901: '阿图什市',
        400902: '阿克陶县',
        400903: '阿合奇县',
        400904: '乌恰县',
        400905: '其它区',
        401001: '喀什市',
        401002: '疏附县',
        401003: '疏勒县',
        401004: '英吉沙县',
        401005: '泽普县',
        401006: '莎车县',
        401007: '叶城县',
        401008: '麦盖提县',
        401009: '岳普湖县',
        401010: '伽师县',
        401011: '巴楚县',
        401012: '塔什库尔干塔吉克自治县',
        401013: '其它区',
        401101: '和田市',
        401102: '和田县',
        401103: '墨玉县',
        401104: '皮山县',
        401105: '洛浦县',
        401106: '策勒县',
        401107: '于田县',
        401108: '民丰县',
        401109: '其它区',
        401201: '伊宁市',
        401202: '奎屯市',
        401203: '伊宁县',
        401204: '察布查尔锡伯自治县',
        401205: '霍城县',
        401206: '巩留县',
        401207: '新源县',
        401208: '昭苏县',
        401209: '特克斯县',
        401210: '尼勒克县',
        401211: '其它区',
        401301: '塔城市',
        401302: '乌苏市',
        401303: '额敏县',
        401304: '沙湾县',
        401305: '托里县',
        401306: '裕民县',
        401307: '和布克赛尔蒙古自治县',
        401308: '其它区',
        401401: '阿勒泰市',
        401402: '布尔津县',
        401403: '富蕴县',
        401404: '福海县',
        401405: '哈巴河县',
        401406: '青河县',
        401407: '吉木乃县',
        401408: '其它区',
        401501: '其它区',
        401601: '其它区',
        401701: '其它区',
        401801: '其它区',
        401901: '其它区',
        402001: '其它区',
        402101: '其它区',
        402201: '其它区',
        402301: '其它区',
    }
};